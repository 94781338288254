import {
  StyleParamType,
  createStylesParams,
  createStylesParam,
  wixFontParam,
  wixColorParam,
} from '@wix/tpa-settings';

export type IStylesParams = {
  isBlogComments: StyleParamType.Boolean;
  commentsSpacing: StyleParamType.Number;
  sidePadding: StyleParamType.Number;
  replyLeftPadding: StyleParamType.Number;
  commentCountFont: StyleParamType.Font;
  commentCountMobileFontSize: StyleParamType.Number;
  commentCountColor: StyleParamType.Color;
  sortingFont: StyleParamType.Font;
  sortingMobileFontSize: StyleParamType.Number;
  sortingColor: StyleParamType.Color;
  headerDividerColor: StyleParamType.Color;
  headerDividerWidth: StyleParamType.Number;
  commentBoxPlaceholderColor: StyleParamType.Color;
  commentBoxPlaceholderMobileFontSize: StyleParamType.Number;
  commentBoxPlaceholderFont: StyleParamType.Font;
  closedCommentBoxFillColor: StyleParamType.Color;
  closedCommentBoxBorderColor: StyleParamType.Color;
  closedCommentBoxBorderWidth: StyleParamType.Number;
  closedCommentBoxCornerRadius: StyleParamType.Number;
  openCommentBoxTextColor: StyleParamType.Color;
  pluginsIconColor: StyleParamType.Color;
  openCommentBoxFillColor: StyleParamType.Color;
  openCommentBoxBorderColor: StyleParamType.Color;
  buttonColor: StyleParamType.Color;
  buttonFont: StyleParamType.Font;
  buttonTextColor: StyleParamType.Color;
  buttonCornerRadius: StyleParamType.Number;
  commentListCommentFont: StyleParamType.Font;
  commentListCommentMobileFontSize: StyleParamType.Number;
  commentListCommentTextColor: StyleParamType.Color;
  commentListSecondaryFont: StyleParamType.Font;
  commentListSecondaryMobileFontSize: StyleParamType.Number;
  commentListSecondaryTextColor: StyleParamType.Color;
  commentListUsernameFont: StyleParamType.Font;
  commentListUsernameMobileFontSize: StyleParamType.Number;
  commentListUsernameTextColor: StyleParamType.Color;
  commentListBadgeColor: StyleParamType.Color;
  commentListHighlightColor: StyleParamType.Color;
  markedCommentCornerRadius: StyleParamType.Number;
  actionsFont: StyleParamType.Font;
  actionsTextColor: StyleParamType.Color;
  actionsIconColor: StyleParamType.Color;
  commentListDividerColor: StyleParamType.Color;
  widgetBackgroundColor: StyleParamType.Color;
  widgetBorderColor: StyleParamType.Color;
  widgetBorderWidth: StyleParamType.Number;
  widgetCornerRadius: StyleParamType.Number;
  filledRatingStar: StyleParamType.Color;
  emptyRatingStar: StyleParamType.Color;
  ratingInfoFont: StyleParamType.Font;
  ratingInfoColor: StyleParamType.Color;
  headerButtonFont: StyleParamType.Font;
  headerButtonColor: StyleParamType.Color;
  headerButtonRadius: StyleParamType.Number;
};

const isBlogComments = createStylesParam('isBlogComments', {
  type: StyleParamType.Boolean,
  getDefaultValue: () => false,
});

export default createStylesParams<IStylesParams>({
  isBlogComments,
  commentCountFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Page-title', { size: 20 }),
  },
  commentCountMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  commentCountColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sortingFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  sortingMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  sortingColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  headerButtonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  headerButtonColor: { type: StyleParamType.Color, getDefaultValue: wixColorParam('color-8') },
  headerButtonRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  headerDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  headerDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  commentBoxPlaceholderMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  commentBoxPlaceholderFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  commentBoxPlaceholderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  closedCommentBoxFillColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  closedCommentBoxBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  closedCommentBoxBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  closedCommentBoxCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  openCommentBoxTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  pluginsIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  openCommentBoxFillColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  openCommentBoxBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M'),
  },
  buttonTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  commentListBadgeColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  commentListHighlightColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.1),
  },
  markedCommentCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  commentListCommentFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 16 }),
  },
  commentListCommentMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
  },
  commentListCommentTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  commentListSecondaryFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  commentListSecondaryMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
  commentListSecondaryTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  commentListUsernameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  commentListUsernameMobileFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
  commentListUsernameTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  actionsFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', { size: 14 }),
  },
  actionsTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  actionsIconColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  commentListDividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  widgetBackgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  widgetBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  widgetBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  widgetCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  commentsSpacing: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  sidePadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ getStyleParamValue }) => {
      return getStyleParamValue(isBlogComments) ? 0 : 20;
    },
  },
  replyLeftPadding: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? 20 : 48),
  },
  filledRatingStar: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  emptyRatingStar: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  ratingInfoFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-S'),
  },
  ratingInfoColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});
