import { ReactionIconType } from '../../../../../services/category-service';
import { AdditionalReactions } from './additional-reactions.const';

export const resolveAdditionalReactions = (
  reactions: AdditionalReactions[],
  mainReaction: ReactionIconType | undefined,
) =>
  reactions.filter(
    (reaction) =>
      !(
        (mainReaction === ReactionIconType.LOVE && reaction === AdditionalReactions.HEART) ||
        (mainReaction === ReactionIconType.THUMBS_UP && reaction === AdditionalReactions.THUMBS_UP)
      ),
  );
