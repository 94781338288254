export enum AdditionalReactions {
  THUMBS_UP = 'thumbsup',
  ANGRY = 'angry',
  CLAP = 'clap',
  HEART = 'heart',
  JOY = 'lol',
  SAD = 'sad',
  OPEN_MOUTH = 'surprised',
  THINKING = 'thinking',
  SMILE = 'smiley',
}
