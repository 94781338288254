import { CommentSortOptions } from '~common/common-types';
import { Ordering } from '@wix/comments-ooi-client/controller';
import { unreachable } from '../../../ts-utils';

type PaginationSettings = {
  sortCommentsBy: CommentSortOptions;
  repliesOnLoadMore: number;
  commentsOnLoadMore: number;
  commentsOnFirstPage: number;
  repliesOnFirstPage: number;
};

type ResolvePaginationConfigParams = {
  settings: PaginationSettings;
  withVotes: boolean;
  withEmotions: boolean;
};

export const resolvePaginationConfig = ({
  settings,
  withVotes,
  withEmotions,
}: ResolvePaginationConfigParams) => {
  const {
    sortCommentsBy,
    repliesOnLoadMore,
    commentsOnLoadMore,
    commentsOnFirstPage,
    repliesOnFirstPage,
  } = settings;

  const ordering = (() => {
    switch (sortCommentsBy) {
      case CommentSortOptions.highestScore:
        return withVotes ? Ordering.MOST_VOTES_FIRST : Ordering.NEWEST_FIRST;
      case CommentSortOptions.lowestScore:
        return withVotes ? Ordering.LEAST_VOTES_FIRST : Ordering.OLDEST_FIRST;
      case CommentSortOptions.mostReactions:
        return withEmotions ? Ordering.MOST_REACTIONS_FIRST : Ordering.NEWEST_FIRST;
      case CommentSortOptions.newest:
        return Ordering.NEWEST_FIRST;
      case CommentSortOptions.oldest:
        return Ordering.OLDEST_FIRST;
      default:
        unreachable(sortCommentsBy);
    }
  })();
  return {
    replyShowMoreLimit: repliesOnLoadMore,
    initialPage: {
      offset: 0,
      commentLimit: commentsOnFirstPage,
      replyLimit: repliesOnFirstPage,
    },
    pagination: {
      commentLimit: commentsOnLoadMore,
      replyLimit: repliesOnFirstPage,
    },
    ordering,
    maxPagesBeforeDrop: {
      commentPages: 4,
      replyPages: 4,
    },
  };
};
