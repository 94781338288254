export enum CommentSortOptions {
  newest = 'newest',
  oldest = 'oldest',
  mostReactions = 'most-reactions',
  highestScore = 'highest-score',
  lowestScore = 'lowest-score',
}

export enum CommentBoxPositionOptions {
  auto = 'auto',
  topOnly = 'TOP_ONLY',
  bottomOnly = 'BOTTOM_ONLY',
}
