export const isEncodedURI = (str: string) => {
  try {
    const decoded = decodeURIComponent(str);
    return decoded !== str;
  } catch (e) {
    return false;
  }
};
export const encodeUrlIfNeeded = (url: string) => {
  return isEncodedURI(url) ? url : encodeURIComponent(url);
};

export const generateDeepLink = (_resourceId: string, deepLinkCommentId: string) => {
  const resourceId = encodeUrlIfNeeded(_resourceId);

  return [
    window?.location.origin ?? '',
    window?.location.pathname ?? '',
    `?resourceId=${resourceId}&commentId=${deepLinkCommentId}`,
  ].join('');
};
