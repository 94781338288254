import { Role } from '@wix/ambassador-comments-v1-category/types';

export enum PostingPermission {
  ALL = 'ALL',
  MEMBER = 'MEMBER',
  ADMIN = 'ADMIN',
  NONE = 'NONE',
}

export const PermissionToRole = {
  ALL: Role.ALL,
  MEMBER: Role.MEMBER,
  ADMIN: Role.ADMIN,
  NONE: Role.ADMIN,
};

export const defaultPermissions = {
  createComment: { role: Role.ALL },
  createReply: { role: Role.ALL },
  createReact: { role: Role.ALL },
};
