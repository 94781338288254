import { DevToolsState } from '@wix/comments-ooi-client/dist/types/component/components/dev-panel/dev-tools-types';

export type DevSettingsConfig = {
  forceDoubleCommentBox?: boolean;
  screenshotMode?: boolean;
};

export const resolveDevSettingsConfig = (
  devSettingsConfig: DevSettingsConfig,
  devToolsState: DevToolsState,
) => {
  if (Object.keys(devSettingsConfig).length === 0) {
    return devToolsState;
  }

  return {
    ...devToolsState,
    forceShowCommentBoxes: devSettingsConfig?.forceDoubleCommentBox,
  } as DevToolsState;
};
