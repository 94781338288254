import { isEncodedURI } from './generate-deep-link';

export const getDeepLinkedComment = (_resourceId: string) => {
  const resourceId = isEncodedURI(_resourceId) ? decodeURIComponent(_resourceId) : _resourceId;

  const locationQuery =
    typeof window !== 'undefined'
      ? Object.fromEntries(new URLSearchParams(window.location.search))
      : {};

  return locationQuery.resourceId === resourceId ? locationQuery.commentId : undefined;
};
