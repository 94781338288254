import { ISettingsGetter, ISettingsParamValuesByType, SettingsParamType } from '@wix/tpa-settings';
import { Category } from '../../../services/category-service';
import settingsParams, { ISettingsParams } from '../settingsParams';

export const excludedParams = [
  'isDividerEnabledMobile',
  'isSortingEnabledMobile',
  'isCommentsCountEnabledMobile',
  'isReplyingToMarkingEnabledMobile',
  'isAvatarsEnabledMobile',
  'isMembersBadgeEnabledMobile',
  'isPublishDateEnabledMobile',
  'isDividerEnabledMobile',
  'settingsUpdateTimestamp',
  'isDisplayMobileOn',
] as const;

export type Settings = {
  [key in keyof ISettingsParams]: ISettingsParams[key] extends SettingsParamType
    ? ISettingsParamValuesByType[ISettingsParams[key]]
    : ISettingsParams[key];
};

type ServerSettings = Omit<
  {
    [K in keyof Required<Category>]: undefined extends Category[K]
      ? Category[K] | undefined
      : never;
  },
  'id' | 'category' | 'revision' | 'guestCommenting' | 'guestReactions' | 'name'
>;

export type WidgetSettings = Omit<Settings, (typeof excludedParams)[number]> & ServerSettings;

export const getWidgetSettings = ({
  isMobile,
  category,
  settingsGetter,
}: {
  settingsGetter: ISettingsGetter;
  category: Category | undefined;
  isMobile: boolean;
}): WidgetSettings => {
  const getScopedParam = createScopedSettingsParamGetter(settingsGetter, isMobile);

  const widgetSettings = Object.keys(settingsParams)
    .filter((key) => !(excludedParams as readonly string[]).includes(key))
    .reduce(
      (result, key) =>
        Object.assign(result, {
          [key]: settingsGetter(settingsParams[key as keyof ISettingsParams]),
        }),
      {} as Settings,
    );

  return {
    ...widgetSettings,
    isDividerEnabled: getScopedParam('isDividerEnabled', 'isDividerEnabledMobile'),
    isSortingEnabled: getScopedParam('isSortingEnabled', 'isSortingEnabledMobile'),
    isCommentsTitleEnabled: getScopedParam(
      'isCommentsTitleEnabled',
      'isCommentsTitleEnabledMobile',
    ),
    isCommentsCountEnabled: getScopedParam(
      'isCommentsCountEnabled',
      'isCommentsCountEnabledMobile',
    ),
    isReplyingToMarkingEnabled: getScopedParam(
      'isReplyingToMarkingEnabled',
      'isReplyingToMarkingEnabledMobile',
    ),
    isAvatarsEnabled: getScopedParam('isAvatarsEnabled', 'isAvatarsEnabledMobile'),
    isMembersBadgeEnabled: getScopedParam('isMembersBadgeEnabled', 'isMembersBadgeEnabledMobile'),
    isPublishDateEnabled: getScopedParam('isPublishDateEnabled', 'isPublishDateEnabledMobile'),
    mainReaction: category?.mainReaction,
    reactionType: category?.reactionType,
    repliesReactionType: category?.repliesReactionType,
    markedCommentLabelType: category?.markedCommentLabelType,
    customMarkedCommentLabel: category?.customMarkedCommentLabel,
    ratingsSettings: category?.ratingsSettings,
    permissionsSettings: category?.permissionsSettings,
  };
};

const createScopedSettingsParamGetter = (getSetting: ISettingsGetter, isMobile: boolean) => {
  const isMobileDisplayOn = getSetting(settingsParams.isDisplayMobileOn);
  return <T extends keyof ISettingsParams>(desktopScope: T, mobileScope: T) =>
    getSetting(
      isMobile && isMobileDisplayOn ? settingsParams[mobileScope] : settingsParams[desktopScope],
    );
};
