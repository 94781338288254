export type RequiredKeys<T, K extends keyof T> = Exclude<T, K> & { [key in K]-?: Required<T[key]> };

export function assertFields<T extends {}, K extends keyof T>(
  obj: T,
  fields: K[],
  objectName: string,
): asserts obj is RequiredKeys<T, K> {
  const notFoundFields = fields.filter((key) => obj[key] === undefined);
  if (notFoundFields.length) {
    throw new Error(
      `${objectName}: Expected fields ${fields.join(
        ', ',
      )}, but these fields where missing: ${notFoundFields.join(', ')}`,
    );
  }
}

export function assertDefined<T = any>(value: T): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw new Error('value is not defined');
  }
}

export function unreachable(param: never) {
  return new Error(`Unreachable case with value ${JSON.stringify(param)}`);
}

// Simplifies the output shown to the user
export type Compute<T> = T extends object
  ? {
      [P in keyof T]: Compute<T[P]>;
    }
  : T;

export type ComputeShallow<T> = T extends object
  ? {
      [P in keyof T]: T[P];
    }
  : T;

export const isDefined = <T>(item: T | null | undefined): item is T =>
  item !== null && item !== undefined;
