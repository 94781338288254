import { InfoCircle } from '@wix/wix-ui-icons-common';
import React from 'react';
import { SectionNotification } from 'wix-ui-tpa/cssVars';

export const InfoAlert = ({ alertText }: { alertText: string }) => {
  return (
    <SectionNotification type="wired">
      <SectionNotification.Icon icon={<InfoCircle />} />
      <SectionNotification.Text>{alertText}</SectionNotification.Text>
    </SectionNotification>
  );
};
